import axios from 'axios'



//发送请求时默认前缀http://192.168.1.66:3000
axios.defaults.baseURL = 'https://song.filterap.com'


//请求拦截，发送请求做了什么
axios.interceptors.request.use(config=>{
    return config
})

//响应拦截，拿到数据后做了什么
axios.interceptors.response.use(res=>{
    //请求成功
    return res
},err=>{
    //请求失败
    return Promise.reject(err)
});



export  default  axios

