import { createStore } from 'vuex'


export default createStore({
  state: {
    navigationData:[]
  },
  getters: {

  },
  mutations: {
    //设置导航数据
    setNavigationData(state , payload){
      // console.log(payload)
      state.navigationData = payload
    }
  },
  actions: {


  },
  modules: {

  }
})
