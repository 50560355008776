<template>
  <div id="app">
      <router-view>

      </router-view>
  </div>
</template>

<style lang="less">
  html,body,#app{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh;
  }



</style>
<script setup>




</script>