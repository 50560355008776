import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconVue from '@element-plus/icons-vue'
import locale from 'element-plus/es/locale/lang/zh-cn'
import './assets/iconfont/iconfont.css'
import './assets/css/globalStyle.css'


const app = createApp(App)


app.use(store)

//防止刷新页面时，
import {setPermissionRouter} from './router/index'
await setPermissionRouter()

app.use(router)
app.use(ElementPlus,{ locale })
for(const [key , component] of Object.entries(ElementPlusIconVue)){
    app.component(key,component)
}







app.mount('#app')




















// app.vue写在script里面  main.js写在app挂载完之后
const debounce = (fn, delay) => {
    let timer
    return (...args) => {
        if (timer) {
            clearTimeout(timer)
        }
        timer = setTimeout(() => {
            fn(...args)
        }, delay)
    }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
    constructor(callback) {
        callback = debounce(callback, 200);
        super(callback);
    }
}
