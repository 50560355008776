// * 根据用户的权限不同，所看到的页面和可操作性也不同

// * admin----所有页面都可以看到

// * vip------具有vip的权限

// *

// * addRouter()

// *

// *准备动态加载的路由

export const DynamicRoutes = [

    {
        path: 'run',
        name: 'run',
        component: () => import('../views/run/index.vue'),
        meta:{
            name:"运营模块",
            icon:"icon-yunying"
        },
        children: [
            {
                path: 'over',//总览模块
                name: 'over_run',
                component: () => import('../views/run/OverView.vue'),
                meta:{
                    name:"总览"
                },
            },
            {
                path: 'brush',//刷单模块
                name: 'brush',
                component: () => import('../views/run/BrushRecord.vue'),
                meta:{
                    name:"增加"
                },
            },
        ]
    },


    {//产品模块
        path: 'product',
        name: 'product',
        component: () => import('../views/product/index.vue'),
        meta:{
            name:"产品模块",
            icon:"icon-chanpin"
        },
        children:[
            {
                path: 'over',
                name:'over_product',
                component:()=>import('../views/product/OverView.vue'),
                meta:{
                    name:"总览"
                },
            },
            {
                path: 'information',
                name:'information_product',
                component:()=>import('../views/product/ProductInformation.vue'),
                meta:{
                    name:"产品信息"
                },
            }
        ]
    },



    {//费用模块
        path: 'fee',
        name: 'fee',
        component: () => import('../views/fee/index.vue'),
        meta:{
            name:"费用模块",
            icon:"icon-feiyong"
        },
        children:[
            {
                path: "over",
                name:'over_fee',
                component:()=>import('../views/fee/OverView.vue'),
                meta:{
                    name:"补单"
                },
            }
        ]
    },



    {//设计模块
        path: 'design',
        name: 'design',
        component: () => import('../views/design/index.vue'),
        meta:{
            name:"项目模块",
            icon:"icon-shejifei"
        },
        children: [
            {
                path: 'over',//总览
                name: 'over_design',
                component: () => import('../views/design/OverView.vue'),
                meta:{
                    name:"总览"
                },
            },
            {
                path: 'increase',//增加
                name: 'increase_design',
                component: () => import('../views/design/DesignIncrease.vue'),
                meta:{
                    name:"增加"
                },
            },
        ]
    },





    {//询盘模块
        path: 'inquiry',
        name: 'inquiry',
        component: () => import('../views/inquiry'),
        meta:{
            name:"询盘模块",
            icon:"icon-xunpan"
        },
        children: [
            {
                path: 'over',//总览模块
                name: 'over_inquiry',
                component: () => import('../views/inquiry/OverView.vue'),
                meta:{
                    name:"总览"
                },
            },
            {
                path: 'increase',//刷单模块
                name: 'increase_inquiry',
                component: () => import('../views/inquiry/InquiryRecord.vue'),
                meta:{
                    name:"增加"
                },
            },
        ]
    },



    {//客户模块
        path: 'custom',
        name: 'custom',
        component: () => import('../views/custom'),
        meta:{
            name:"客户模块",
            icon:"icon-kehu"
        },
    },


    {//权限模块
        path: 'limit',
        name: 'limit',
        component: () => import('../views/limit/index.vue'),
        meta:{
            name:"权限模块",
            icon:"icon-jiaosequanxian"
        },
        children: [
            {
                path: 'user',//用户管理
                name: 'limit_user',
                component: () => import('../views/limit/UserManage.vue'),
                meta:{
                    name:"用户管理"
                },
            },
            {
                path: 'role',//角色管理
                name: 'limit_role',
                component: () => import('../views/limit/RoleManage.vue'),
                meta:{
                    name:"角色管理"
                },
            },
            {
                path: 'department',//部门管理
                name: 'limit_department',
                component: () => import('../views/limit/DepartmentManage.vue'),
                meta:{
                    name:"部门管理"
                },
            },
            {
                path: 'permission',//权限管理
                name: 'limit_permission',
                component: () => import('../views/limit/PermissionManage.vue'),
                meta:{
                    name:"权限管理"
                },
            },
        ]
    }























    ]