import { createRouter ,createWebHashHistory} from 'vue-router'
import {ElMessage} from "element-plus"
import {getUserPermission} from "@/api/limit";
import {recursionRouter, transPermissionListToTreeData} from "@/utils";
import {DynamicRoutes} from "@/router/dynamicRoutes";
import LoginView from '../views/LoginView.vue'
import store from "@/store";

//这里为静态路由
const routes = [
  {
    path:'/',
    redirect:'/login',
  },
  {
    path:'/login',
    name:'login',
    component:LoginView
  },
  {
    path:'/register',
    name:'register',
    component: ()=>import('../views/RegisterView.vue')
  },
  {
    path:'/forget',
    name:'forget',
    component: () =>import('../views/ForgetPasswordView.vue')
  },
  {
    path: '/index',
    name: 'index',
    redirect: '/index/home',
    component: () => import('../views/IndexView.vue'),
    children:[
      {
        path:'home',
        name:'home',
        component:()=>import('../views/Home/index.vue')
      },









    ]
  },
]







const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})















//在跳转页面时判断一下当前该用户拥有什么权限
export async function setPermissionRouter(){
  if(localStorage.getItem('userInfo')){//判断一下有没有这个信息
    let token = JSON.parse(localStorage.getItem('userInfo')).user_id
    //向后端发送请求去查找这个时候登上来是什么样的角色，他有什么样的权限，我要得到一个数组
    await getUserPermission({
      user_id: token
    })
        .then(res=>{
          if(res.data.code === -1){
            ElMessage.error("出现错误,请稍后重试")
          }
          else if(res.data.code === 1){
            ElMessage.warning("当前不具有操作权限")
          }
          else if(res.data.code === 0){
            //将得到的权限数据转化成树形结构
            let permissionTree = transPermissionListToTreeData(res.data.data , '1')
            //将转化之后的树形数据和和动态路由进行对比,,,,,,然后进行筛选
            //比对路由权限,获取最终的动态路由数据
            let data = recursionRouter( permissionTree , DynamicRoutes)
            //将比对完之后的路由数据添加到静态路由中
            data.forEach(item=>{
              router.addRoute('index', item);
            })
            //将比对完的权限数组往vuex中也存储一份
            store.commit('setNavigationData' , data)
          }
        })
        .catch(err=>{console.log(err)})
  }
}



//路由前置守卫，一般用于验证用户身份，检查权限
// to 即将进入的目标路由对象,包含路径，参数，查询参数
// from 当前导航正要离开的路由对象，包含了当前的路由信息
// next 函数，用于控制导航行为，接受一个参数，用于指定导航的目标路由
router.beforeEach(async (to, from ,next)=>{
  //给文档设置标题
  document.title = to.meta.title || '运营后台管理系统'

  //管理系统两个常见的经典的逻辑
  if(JSON.parse(localStorage.getItem('userInfo'))){
    //1、如果访问四大天王界面，但是有token，那么应该跳转到首页，
    if(to.path==='/login'||to.path==='/' ||to.path==='/forget'||to.path==='/register'){
      next('/index/home')
      return
    }
    else{//3、如果访问除登录、注册、找回密码之外的界面，并且有token，那么获取一下权限来看看导航咋设置
      //发送请求获取权限得出动态路由来设置静态路由
      await setPermissionRouter()
    }
  }
  else{//2、如果访问除四大天王界面之外的其他界面，但是没有token，那么要跳转到其他页面
    if(to.path!=='/login'&&to.path!=='/' &&to.path!=='/forget'&&to.path!=='/register'&&!token){
      next('/')
      return
    }
  }
  next()
})





export default router
