import axios from '../utils/http'



//登录
export const loginAccount = data =>axios.post('/login/loginAccount',data)
//注册
//向后端发送验证码
export const sendMail = data=>axios.post('/register/sendMail',data)
//注册账户
export const registerAccount = data=>axios.post('/register/registerAccount',data)
//找回密码
export const findAccountPassword = data=>axios.post('/register/findAccountPassword', data)