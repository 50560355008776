import pinyin from "js-pinyin";
pinyin.setOptions({checkPolyphone:false , charCase : 1})








//获取当前日期时间
export function getCurrentDataTime(){
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let Hours = date.getHours();
    let Minutes = date.getMinutes();
    let Seconds = date.getSeconds();
    if(month < 10) {
        month = "0" + month;
    }
    if (day < 10) {
        day = "0" + day;
    }
    if (Hours < 10) {
        Hours = "0" + Hours;
    }
    if ( Minutes < 10) {
        Minutes = "0" + Minutes;
    }
    if (Seconds < 10) {
        Seconds = "0" + Seconds;
    }
    return year + '-' + month + '-' + day + ' ' + Hours + ':' + Minutes + ':' + Seconds
}





//部门数据转化成树形结构
export function transListToTreeData(list , rootValue) {
    const arr = []

    list.forEach(item =>{
        if(item.department_pid === rootValue){
            //找到了匹配的节点
            arr.push(item)

            //当前节点的id 和 当前节点的子节点的pid是相等的
            item.children = transListToTreeData(list , item.department_id)
            //将子节点赋值给当前节点
        }
    })

    return arr
}



//权限数据转化成树形结构
export function transPermissionListToTreeData(list , rootValue) {
    const arr = []

    list.forEach(item =>{
        if(item.permission_pid === rootValue){
            //找到了匹配的节点
            arr.push(item)

            //当前节点的id 和 当前节点的子节点的pid是相等的
            item.children = transPermissionListToTreeData(list , item.permission_id)
            //将子节点赋值给当前节点
        }
    })

    return arr
}






//比对路由   得到最终应该渲染的动态路由
export function recursionRouter(userRouter , allRouter){
    //最后比对完之后的路由
    let realRouter = []

    for(let i = 0 ; i < allRouter.length ; i++){
        for(let j = 0 ; j < userRouter.length ; j++) {

            if(allRouter[i].meta.name === userRouter[j].permission_name) {

                if(userRouter[j].children  && userRouter[j].children.length >0){
                    allRouter[i].children = recursionRouter(userRouter[j].children , allRouter[i].children)
                }
                realRouter.push(allRouter[i])
            }

        }
    }

    return realRouter
}
















//将标准时间转成合适的日期格式
export function transStandardToFormatTime(theDate){
    let year = theDate.getFullYear();
    let month = theDate.getMonth() + 1;
    let day = theDate.getDate();
    let Hours = theDate.getHours();
    let Minutes = theDate.getMinutes();
    let Seconds = theDate.getSeconds();
    if(month < 10) {
        month = "0" + month;
    }
    if (day < 10) {
        day = "0" + day;
    }
    if (Hours < 10) {
        Hours = "0" + Hours;
    }
    if ( Minutes < 10) {
        Minutes = "0" + Minutes;
    }
    if (Seconds < 10) {
        Seconds = "0" + Seconds;
    }
    return year + '-' + month + '-' + day + ' ' + Hours + ':' + Minutes + ':' + Seconds
}










//将标准时间转成合适的日期格式
export function transStandardToFormat(theDate){
    let year = theDate.getFullYear();
    let month = theDate.getMonth() + 1;
    let day = theDate.getDate();
    if(month < 10) {
        month = "0" + month;
    }
    if (day < 10) {
        day = "0" + day;
    }
    return year + '-' + month + '-' + day
}




//返回字符串的首字符并转化成小写
export const transStringToCamelChars = (str)=>{
    return  pinyin.getCamelChars(str).toLocaleLowerCase()
}



//判断一下传过来的值是不是数值  即整数或者小数
export const checkIsIntegerOrDecimal = (value)=> {
    // let re1 = /^[0-9]*$/        //输入的值是整数
    // let re2 = /^[0-9]*(\.[0-9]*)?$/  //输入的值是小数
    //
    // return re1.test(value) || re2.test(value);

    let re0 = /^\d+(\.\d+)?$/

    return re0.test(value)
}

















