import axios from '../utils/http'



//部门管理模块
//获取全部部门信息
export const getAllDepartmentInfo = ()=>axios.get('/department/getAllDepartmentInfo')
//新增一条部门信息
export const increaseDepartInfo = data=>axios.post('/department/increaseDepartInfo',data)
//获取一条部门信息
export const getDepartInfoById = data=>axios.post('/department/getDepartInfoById',data)
//修改一条部门信息
export const updateDepartInfo = data=>axios.post('/department/updateDepartInfo' , data)
//删除一条部门信息
export const deleteDepartInfo = data=>axios.post(('/department/deleteDepartInfo'),data)








//权限管理模块
export const getAllPermissionsInfo = ()=>axios.get('/permission/getAllPermissionsInfo')
//往数据库里增加一条权限数据
export const increasePermissionInfo = data=>axios.post('/permission/increasePermissionInfo',data)
//往数据库里增加一条子权限数据
export const increasePermissionSonInfo = data=>axios.post('/permission/increasePermissionSonInfo',data)
//删除一条权限数据
export const deletePermissionInfo = data=>axios.post('/permission/deletePermissionInfo',data)
//更新一条权限数据
export const updatePermissionInfo = data=>axios.post('permission/updatePermissionInfo',data)










//角色管理模块
//获取全部角色信息
export const getAllRoleInfo = ()=>axios.get('/role/getAllRoleInfo')
//新增一条角色信息
export const increaseRoleInfo = data=>axios.post('/role/increaseRoleInfo',data)
//修改一条角色信息
export const updateRoleInfo = data=>axios.post('/role/updateRoleInfo' , data)
//删除一条角色信息
export const deleteRoleInfo = data=>axios.post(('/role/deleteRoleInfo'),data)
//获取全部已启用的角色信息
export const getAllRoleInfoHasState = ()=>axios.get('/role/getAllRoleInfoHasState')
//根据角色id查询角色所应该具有的权限
export const getRoleInfoPermissionByID = data=>axios.post('/role/getRoleInfoPermissionByID',data)
//给角色设置权限
export const setRolePermissionByID = data =>axios.post('/role/setRolePermissionByID',data)












//员工管理模块
//获取全部员工信息
export const getAllUsersInfo = ()=>axios.get('/users/getAllUsersInfo')
//获取一条员工信息中的角色 通过员工id
export const getUserInfoRoleByID = data =>axios.post('users/getUserInfoRoleByID',data)
//给员工设置角色
export const setUserRoleByID = data=>axios.post('/users/setUserRoleByID',data)
//通过员工的token去查找，最终返回该员工所有的权限数组
export const getUserPermission = data=>axios.post('users/getUserPermission',data)
//获取一条员工所具有的角色名称
export const getUserRoleNameByID = data =>axios.post('users/getUserRoleNameByID',data)
//更新员工姓名 手机号 微信号 部门 入职日期
export const updateUserInfo = data=>axios.post('/users/updateUserInfo',data)








