<script setup>
import {nextTick, ref} from "vue";
  import {useRouter} from "vue-router";
  import {ElMessage} from "element-plus";
  import {loginAccount} from "@/api/user";

  const  router = useRouter()
  const ruleForm  = ref({
    email:'',
    password:'',
  })
  const ruleFormRef = ref(null)
  const rules = ref({
    email:[
      {required:true, message:'邮箱不能为空' , trigger:'blur'},
      {type:"email" , message: "请输入合法邮箱", trigger: 'blur'}
    ],
    password:[
      { required:true,message:'密码不能为空',trigger:'blur'},
      { min:6, max:12, message: '长度在6到12位', trigger:'blur' },
    ]
  })
  // { pattern:/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/, message: '密码中必须包含字母和数字', trigger: 'blur'}

  //邮箱 和 密码  的校验信息
  const emailValidatorMessage = ref()
  const passwordValidatorMessage = ref()








  //前往注册页
  const registerGo = ()=>{
    router.push('/register')
  }

  //前往找回密码页
  const forgetGo = ()=>{
    router.push(('/forget'))
  }


  //提交登录，将用户名密码提交到后端检验
  const loginSubmit = ()=>{
    //点击完登录之后先进行验证，看是否填写完整，再进行判断
    ruleFormRef.value.validate(async (valid)=> {
      if (valid) {//当所有内容验证通过时，则向后端发送数据
        const data = {
          email: ruleForm.value.email,
          password: ruleForm.value.password,
        }
        await loginAccount(data)
            .then(res=>{
              if(res.data.data === -1){
                ElMessage.error("出现错误，请稍后重试")
              }
              else if(res.data.code === 1){   //密码错误
                passwordValidatorMessage.value = ''
                nextTick(()=>{
                  passwordValidatorMessage.value = res.data.message
                })
              }
              else if(res.data.code === 2){   //用户不存在
                emailValidatorMessage.value = ''
                nextTick(()=>{
                  emailValidatorMessage.value = res.data.message
                })
              }
              else if(res.data.code === 0){
                ElMessage.success("登录成功")
                localStorage.setItem('userInfo',JSON.stringify(res.data.data))
                router.push('/index')
              }
            })
            .catch(err=>{console.log(err)})
      }
    })
  }
</script>







<template>



  <div class="login-box">
    <div class="box">
      <h2>瑞智科技</h2>

      <div class="login-form">
        <el-form
            ref="ruleFormRef"
            style="max-width: 600px"
            :model="ruleForm"
            :rules="rules"
            label-width="auto"
            class="demo-ruleForm"
            status-icon
        >
          <el-form-item label="邮箱" prop="email"  :error="emailValidatorMessage">
            <el-input v-model="ruleForm.email"  size="large" placeholder="请输入邮箱账号"  />
          </el-form-item>

          <div style="width: 100%;height: 15px"></div>

          <el-form-item label="密码" prop="password"  :error="passwordValidatorMessage">
            <el-input v-model="ruleForm.password" size="large" type="password" show-password  placeholder="请输入密码"  />
          </el-form-item>

          <div style="width: 100%;height: 15px"></div>

          <el-form-item>
            <el-button color="#303437" dark @click="loginSubmit" size="large" style="width: 100%;font-size: 16px;font-family: 黑体,serif">登录</el-button>
          </el-form-item>


        </el-form>

      </div>
      <div class="register-forget">
        <span @click="registerGo">去注册</span>&nbsp;|&nbsp;<span @click="forgetGo">忘记密码？</span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">

/*设置背景*/
.login-box{
  width: 100%;
  height: 100%;
  background: url("../assets/LoginView/banner.jpg") repeat;

  /*设置登录盒子位置和大小*/
  .box {
    background-color: #ffffff;
    border-radius: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -190px 0 0 -240px;
    padding: 40px  40px  40px  40px;
    width:400px;
    height:300px;

    h2{
      text-align: center;
      color: #444444;
      margin: 0  0   30px   0;
      height: 50px;
      line-height: 50px;
    }

    .login-form {
      position: relative;
      height: 200px;
      width: 100%;
      //background: #00B7FF;

      :deep .el-form-item__label {
        font-weight: bold;
        font-size: 16px;
        font-family: '黑体','微软雅黑',serif;
      }

    }

    .register-forget{
      text-align: right;
      font-size: 12px;
      color: #00B7FF;
    }

    .register-forget span{
      cursor: pointer;
    }
    /* 当鼠标悬停在.register-forget元素上时 */
    .register-forget span:hover{
      opacity: 0.6;
    }
    /* 当鼠标按下在.register-forget元素上时 */
    .register-forget span:active{
      opacity: 1;
    }
  }
}



</style>